import communicationSaga from "ui/pages/communications/redux/sagas";
import dashboardSaga from "ui/pages/Dashboard/redux/sagas";
import marketingSaga from "ui/pages/marketing/redux/sagas";
import webpageDetailsSaga from "ui/pages/TemplatePreview/redux/sagas";
import transactionSaga from "ui/pages/transactions/redux/sagas";
import scheduleSaga from "ui/pages/schedule/redux/sagas";
import partnershipSaga from "ui/pages/Partnerships/redux/sagas";
import exlyFeedbackSaga from "ui/pages/ExlyFeedbacks/redux/sagas";
import whatsappTemplateSaga from "ui/pages/whatsappBroadcast/redux/sagas";
import salesPagesSaga from "ui/pages/SalesPages/Redux/sagas";
import communitySaga from "ui/pages/ManageBrandedCommunity/redux/sagas";
import exlySegmentSaga from "ui/pages/customers/Segments/redux/sagas";
import myAccount from "features/Account/redux/Account.saga";
import thankyouPageSaga from "ui/pages/ThankYou/redux/sagas";
import mediaLibrarySaga from "features/MediaLibrary/redux/MediaLibrary.saga";
import onboardingSaga from "features/Onboarding/redux/Onboarding.saga";

export default [
  onboardingSaga,
  dashboardSaga,
  communicationSaga,
  marketingSaga,
  mediaLibrarySaga,
  webpageDetailsSaga,
  transactionSaga,
  scheduleSaga,
  partnershipSaga,
  exlyFeedbackSaga,
  whatsappTemplateSaga,
  salesPagesSaga,
  communitySaga,
  exlySegmentSaga,
  myAccount,
  thankyouPageSaga,
];
